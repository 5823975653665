import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/DefaultLayout'
import { normalizeArray } from 'lib/graphql/normalize'
import { getArticles } from '../helpers'
import ArticleListContent from '../index'

const ArticleList = ({ data: { articleData }, pageContext: { title } }) => {
  const articles = getArticles(normalizeArray(articleData))

  return (
    <DefaultLayout title={title}>
      <ArticleListContent articles={articles} title={title} />
    </DefaultLayout>
  )
}

ArticleList.propTypes = {
  data: PropTypes.shape({
    articleData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
}

// we need to filter out a specific article, for all new components to be visible in GraphQL
// This is due to limitations of gatsby-source-contentful plugin
export const query = graphql`
  query test($articleTypes: [String!]!) {
    articleData: allContentfulArticle(
      filter: {
        type: { in: $articleTypes }
        contentful_id: { ne: "1vJlzThwX4mJVEf6rIqyhN" }
      }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...ArticleCardFields
        }
      }
    }
  }
`

export default ArticleList
